// DOM Ready functions using custom async function so it only funs when jQuery has been fully loaded
async.push(["ready", function() {
    initChatBox();
}]);


window.initChatBox = function initChatBox() {
    var windowWidth = window.matchMedia("(max-width: 991px)")
    if (windowWidth.matches) {

        LiveChatWidget.on('visibility_changed', function(data) {
            switch (data.visibility) {
                case "maximized":
                    $('html').addClass('modal-chat');
                    $('#chat-widget-container').addClass('max');
                    break;
                case "minimized":
                    $('html').removeClass('modal-chat');
                    $('#chat-widget-container').removeClass('max');
                    break;
            }
        });
    }
}